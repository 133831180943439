import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/modules/auth';
import { AuthModel } from 'src/app/modules/auth/models/auth.model';


@Injectable()
export class manageHttpRequest implements HttpInterceptor {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    authData:AuthModel | undefined;
    constructor() {
        
     }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if account is logged in and request is to the api url
        const lsValue:any = localStorage.getItem(this.authLocalStorageToken);        
        lsValue ? this.authData = JSON.parse(lsValue) : undefined ;
        
        const isLoggedIn = this.authData ? true : false;
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {            
            request = request.clone({
                setHeaders: { 
                    withCredentials : 'true',
                    Authorization: `Bearer ${this.authData?.authToken}`,
                 },
                 withCredentials:this.authData ? true : false,
            });
        }
        return next.handle(request);
    }
}
import { UserModel } from "..";
import { authApiInterFace } from "./auth.interfaces";

export class AuthModel {
  
  authToken: string | undefined;
  userData:UserModel | undefined

  constructor( _auth?:authApiInterFace,init?:boolean){    
    (init && _auth) ? this.setAuth(_auth) : null;
  }
  

  setAuth(_auth?:authApiInterFace) {
    this.authToken = _auth?.object.token;
    this.userData = _auth?.object.user;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsContainer } from './toasts-container.component';



@NgModule({
  declarations: [ToastsContainer],
  imports: [
    CommonModule,
    NgbToastModule
  ],
  exports:[
    ToastsContainer
  ]
})
export class ToasterModule { }

import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';
import { roleInterface } from './auth.interfaces';

export interface permission{
  id:number;
  name:string;
}
export class UserModel {
  created_at: string | undefined
  email: string
  password: string
  id: number
  name: string | undefined
  notifyOn: boolean | undefined
  phone: string | undefined
  propertiesNotify: boolean | undefined
  roles:roleInterface[]
  pic:string ;
  permissions:permission[];
  // id: number;
  // username: string;
  // password: string;
  // fullname: string;
  // email: string;
  // pic: string;
  // roles: number[] = [];
  // occupation: string;
  // companyName: string;
  // phone: string;
  // address?: AddressModel;
  // socialNetworks?: SocialNetworksModel;
  // // personal information
  // firstname: string;
  // lastname: string;
  // website: string;
  // // account information
  // language: string;
  // timeZone: string;
  // communication: {
  //   email: boolean;
  //   sms: boolean;
  //   phone: boolean;
  // };
  // // email settings
  // emailSettings?: {
  //   emailNotification: boolean;
  //   sendCopyToPersonalEmail: boolean;
  //   activityRelatesEmail: {
  //     youHaveNewNotifications: boolean;
  //     youAreSentADirectMessage: boolean;
  //     someoneAddsYouAsAsAConnection: boolean;
  //     uponNewOrder: boolean;
  //     newMembershipApproval: boolean;
  //     memberRegistration: boolean;
  //   };
  //   updatesFromKeenthemes: {
  //     newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
  //     tipsOnGettingMoreOutOfKeen: boolean;
  //     thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
  //     newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
  //     tipsOnMetronicBusinessProducts: boolean;
  //   };
  // };

  constructor(){
    
  }

  setUser(_user: unknown) {
    const user = _user as UserModel;    
    this.pic = user.pic || 'assets/media/avatars/blank.png';
    this.created_at = user.created_at || '';
    this.email = user.email || '';
    this.password = user.password || '';
    this.id = user.id;
    this.name = user.name || '';
    this.phone = user.phone;
    this.propertiesNotify = user.propertiesNotify;
    this.roles = user.roles || {};
    this.permissions = user.permissions;
    // this.id = user.id;
    // this.username = user.username || '';
    // this.password = user.password || '';
    // this.fullname = user.fullname || '';
    // this.email = user.email || '';
    // this.pic = user.pic || './assets/media/users/default.jpg';
    // this.roles = user.roles || [];
    // this.occupation = user.occupation || '';
    // this.companyName = user.companyName || '';
    // this.phone = user.phone || '';
    // this.address = user.address;
    // this.socialNetworks = user.socialNetworks;
  }
}

